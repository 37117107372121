import { post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

interface UseUploadResumeParams {
  placementCandidateId: string;
  file: File;
}

const resumePresignedUrlResponseSchema = z
  .object({
    data: z.object({
      type: z.literal("resume-presigned-urls"),
      attributes: z.object({
        presignedUrl: z.string(),
      }),
    }),
  })
  .strict();

export type PresignedUrlResumeResponseDto = z.infer<typeof resumePresignedUrlResponseSchema>;

export function useUploadResume(
  options?: UseMutationOptions<unknown, unknown, UseUploadResumeParams>
) {
  return useMutation({
    mutationFn: async (params: UseUploadResumeParams) => {
      const { placementCandidateId, file } = params;
      const presignedUrlResponse = await post({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/placement-candidates/${placementCandidateId}/resume-presigned-urls`,
        responseSchema: resumePresignedUrlResponseSchema,
        data: {
          data: {
            type: "resume-presigned-urls",
            attributes: { placementCandidateId },
          },
        },
      });

      await axios.put(presignedUrlResponse.data.data.attributes.presignedUrl, file, {
        headers: { "Content-Type": file.type },
      });
      logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_RESUME_UPLOADED, {
        placementCandidateId,
        fileName: file.name,
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_RESUME_UPLOAD_FAILURE,
    },
    ...options,
  });
}
