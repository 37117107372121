import { Skeleton, Stack } from "@mui/material";

export function PdfViewerLoadingSkeleton() {
  return (
    <Stack alignItems="start" direction="column" padding={2} spacing={1}>
      <Skeleton variant="text" width="60%" height={40} />
      <Skeleton variant="rectangular" width="100%" height={300} />

      <Skeleton variant="text" width="80%" height={40} />
      <Skeleton variant="rectangular" width="100%" height={250} />
    </Stack>
  );
}
