import { isDefined } from "@clipboard-health/util-ts";
import throttle from "lodash/throttle";
import { type UIEvent, useMemo } from "react";

import { usePdfViewerContext } from "./context";

/**
 * Calculates current page based on the scroll position. Basic logic is as follows:
 * When user scrolls down, page is considered as 'current' if it's scrolled down at least 50% of the way
 */
export const usePdfScroll = () => {
  const { numberOfPages, setCurrentPage } = usePdfViewerContext();

  const onScroll = useMemo(
    () =>
      throttle((event: UIEvent<HTMLDivElement>) => {
        const scrollContainer = event.currentTarget;
        if (!isDefined(scrollContainer) || !isDefined(numberOfPages)) {
          return;
        }

        const pageSize = scrollContainer.scrollHeight / numberOfPages;
        const remainingScroll =
          scrollContainer.scrollHeight - (scrollContainer.clientHeight + scrollContainer.scrollTop);

        const remainingPages = Math.round(remainingScroll / pageSize);
        const currentPage = Math.max(numberOfPages - remainingPages, 1);

        setCurrentPage(currentPage);
      }, 10),
    [numberOfPages, setCurrentPage]
  );

  return {
    onScroll,
  };
};
