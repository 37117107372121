import { ErrorPage } from "@src/appV2/Errors/ErrorPage";

import { SupportContext } from "../support/constants";

export function PdfViewerError() {
  return (
    <ErrorPage
      title=" Oops! We are having troubles loading this PDF file"
      supportContext={SupportContext.PDF_LOAD_FAILURE}
      description="Please go back or restart the app."
    />
  );
}
