import { Device } from "@capacitor/device";
import { isIosPlatform } from "@src/appV2/lib";
import semVer from "semver";

const MIN_IOS_VERSION = "16.0.0";

export const isPdfViewerSupportedOnPlatform = async () => {
  if (!isIosPlatform()) {
    return true;
  }

  const { osVersion } = await Device.getInfo();

  const semanticVersion = semVer.coerce(osVersion);
  if (!semanticVersion) {
    return false;
  }

  return semVer.gte(semanticVersion, MIN_IOS_VERSION);
};
