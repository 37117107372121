import { toError } from "@clipboard-health/util-ts";
import { APP_V2_APP_EVENTS, logError } from "@src/appV2/lib/analytics";
import { pdfjs } from "react-pdf";

/**
 * We use react-pdf library for our pdf viewer which under the hood needs, PDF.js to work.
 * Essential step in its set up is providing a source url for background worker.
 * @see https://github.com/wojtekmaj/react-pdf#configure-pdfjs-worker for more details
 *
 * @param pdfWorkerSourceUrl Url for actual js file containing pdf.js worker code
 */
export const setupPdfViewer = (pdfWorkerSourceUrl: string) => {
  try {
    pdfjs.GlobalWorkerOptions.workerSrc = pdfWorkerSourceUrl;
  } catch (maybeError: unknown) {
    const error = toError(maybeError);

    logError(APP_V2_APP_EVENTS.PDF_LIBRARY_SETUP_FAILURE, {
      error,
      metadata: {
        pdfWorkerSourceUrl,
      },
    });
  }
};
