// eslint-disable-next-line no-restricted-imports
import "react-pdf/dist/Page/AnnotationLayer.css";
// eslint-disable-next-line no-restricted-imports
import "react-pdf/dist/Page/TextLayer.css";

import { isDefined } from "@clipboard-health/util-ts";
import { alpha, Box } from "@mui/material";
import { UnsupportedPdfPlatformMessage } from "@src/appV2/Agreements/UnsupportedPdfPlatformMessage";
import { APP_V2_APP_EVENTS, logError } from "@src/appV2/lib/analytics";
import { useRef } from "react";
import { Document, Page } from "react-pdf";

import { useResizeObserver } from "../lib/utils/useResizeObserver";
import { usePdfViewerContext } from "./context";
import { PdfViewerError } from "./PdfViewerError";
import { PdfViewerLoadingSkeleton } from "./PdfViewerLoadingSkeleton";
import { PdfViewerToolbar } from "./PdfViewerToolbar";
import { usePdfScroll } from "./usePdfScroll";

export interface PdfViewerProps {
  maxWidth?: number;
}

export function PdfViewer(props: PdfViewerProps) {
  const { maxWidth = 800 } = props;

  const { numberOfPages, onDocumentLoadSuccess, isSupportedPlatform, pdfUrl } =
    usePdfViewerContext();

  const scrollContainerRef = useRef<HTMLDivElement>();
  const size = useResizeObserver(scrollContainerRef.current);
  const { onScroll } = usePdfScroll();

  const pageWidth = Math.min(size.width, maxWidth);
  const isLoading = !numberOfPages;

  if (!isDefined(pdfUrl)) {
    return null;
  }

  return (
    <Box
      sx={{
        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: (theme) => theme.palette.background.paper,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxHeight: "100%",
        ".react-pdf__Document": {
          width: isLoading || !pageWidth ? "100%" : pageWidth,
        },
        ".react-pdf__Page": {
          boxShadow: (theme) => `${alpha(theme.palette.grey[700], 0.2)} 0px 2px 8px 0px`,
          width: isLoading || !pageWidth ? "100%" : pageWidth,
        },
      }}
      aria-label="Pdf Viewer Container"
    >
      {!isLoading && <PdfViewerToolbar />}

      {isSupportedPlatform ? (
        <Box
          ref={scrollContainerRef}
          sx={{
            overflow: "auto",
            display: "flex",
            justifyContent: "space-around",
          }}
          aria-label="Pdf Document Container"
          onScroll={onScroll}
        >
          <Document
            file={pdfUrl}
            error={<PdfViewerError />}
            loading={<PdfViewerLoadingSkeleton />}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(error) => {
              logError(APP_V2_APP_EVENTS.PDF_LOAD_FAILURE, {
                error,
                metadata: {
                  pdfUrl,
                },
              });
            }}
          >
            {Array.from({ length: numberOfPages ?? 0 }).map((_, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={pageWidth}
                loading={<PdfViewerLoadingSkeleton />}
              />
            ))}
          </Document>
        </Box>
      ) : (
        <UnsupportedPdfPlatformMessage />
      )}
    </Box>
  );
}
