import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { PdfViewerDownloadButton } from "@src/appV2/PdfViewer/PdfViewerDownloadButton";

import { usePdfViewerContext } from "./context";

export function PdfViewerToolbar() {
  const { currentPage, numberOfPages } = usePdfViewerContext();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      overflow="auto"
      spacing={1}
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        borderBottom: (theme) => `1px solid ${theme.palette.grey[400]}`,
        flexShrink: 0,
        px: 3,
        py: 0,
      }}
    >
      <Text sx={{ padding: 1 }}>
        {isDefined(numberOfPages) && `Page ${currentPage} of ${numberOfPages}`}
      </Text>

      <PdfViewerDownloadButton />
    </Stack>
  );
}
