import { LoadingButton } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import DownloadIcon from "@mui/icons-material/Download";
import { usePdfViewerContext } from "@src/appV2/PdfViewer/context";

export function PdfViewerDownloadButton(): JSX.Element {
  const { fileName, downloadPdf, isDownloading } = usePdfViewerContext();

  return (
    <LoadingButton
      size="small"
      startIcon={<DownloadIcon />}
      isLoading={isDownloading}
      disabled={!isDefined(fileName)}
      onClick={async () => {
        void downloadPdf();
      }}
    >
      Download
    </LoadingButton>
  );
}
